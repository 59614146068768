<template>
	<div class="footer_menu" v-if="menus && menus.length > 0">
		<div class="menu-item" v-for="menu in menus">
			<div class="item" @click="showChildren(menu)">
				<div class="title">{{ menu.title }}</div>
				<div class="icon">
					<img :class="menu.isOpen ? 'open':''" src="~/assets/img/header/icon_more.svg"/>
				</div>
			</div>
			<div class="item-children" v-if="menu.children && menu.children.length > 0 && menu.isOpen">
				<div class="c-item" :class="[(item.title=='最强称号争霸赛' && locale=='en')
          || ((item.title=='常见问题' || item.title=='WEEX学堂' || item.title=='媒体报道') && (locale=='zh-TW' || locale=='en' || locale=='ko')
          || (item.title=='Career Opportunity'&&locale=='en')
          || (item.title=='채용공고'&&locale=='ko')
          || (item.title=='WEEX Blog'&&locale=='en')
          || (item.title=='공고알림'&&locale=='ko'))
          || (item.title=='Brand Ambassador'&&locale=='en') ? 'hide' : '']"
						 v-for="item in menu.children">
						 <div v-if="item.url=='?fromFooterAppDownload=Y'" class="a-link" @click="goDownload">{{ item.title }}</div>
						 <a v-else-if="ckLink(item.url)" class="a-link" :href="item.url" target="_blank">{{ item.title }}</a>
						 <nuxt-link v-else :to="$i18n.path(item.url)"  class="a-link">{{ item.title }}</nuxt-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			locale: ""
		}
	},
	props: {
		menus: {
			type: Array,
			default: () => []
		}
	},
	methods: {
		clickMenu(path) {
			if(path=="?fromFooterAppDownload=Y"){
				this.goDownload();
			}else if(path=='topic/agent-introduction-topic') { //合夥人頁面特別處理
				if(this.locale == 'zh-CN' || this.locale == 'zh-TW') {
					this.goto(path)
				}
				else {
					let href = '';
					if(this.locale == 'ko') {
						href = 'https://forms.gle/weWUqWDCdNaKYtg46'
					}else {
						href = 'https://forms.gle/bZq8GK2uQ4n4f9CH8';
					}
					window.open(href)
				}
			} else {
				if (this.ckLink(path)) window.open(path);
				else this.goto(path);
			}
		},
		ckLink(path) {
			const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/g;
			return reg.test(path);
		},
		goto(path) {
			this.$router.push(this.$i18n.path(path));
		},
		showChildren(item) {
			if (item.children && item.children.length > 0) {
				item.isOpen = !item.isOpen;
			} else this.clickMenu(item.url);
		},
		goDownload() {
			if(location.href.indexOf("fromFooterAppDownload=Y")!=-1){
				document.querySelector(".downloadApp").scrollIntoView();
			}else{
				if(location.pathname=="/zh-CN/" || location.pathname=="/zh-TW/" || location.pathname=="/en/"){
					document.querySelector(".downloadApp").scrollIntoView();
				}else{
					this.$router.push(this.$i18n.path('?fromFooterAppDownload=Y'));
				}
			}
		},
	},
	mounted() {
		this.locale = this.$i18n.locale;
	}
};
</script>

<style lang="less">
.footer_menu {
	.menu-item {
		.item {
			background: @theme-black;
			display: flex;
			justify-content: space-between;
			.pxToVwH5Padding(0, 30);
			.pxToVwH5(line-height, 100);
			.icon {
				overflow: unset;;
			}

			.title {
				.pxToVwH5(font-size, 28);
				font-weight: 500;
				color: #F9F9F9;
				.medium-font();
			}

			img {
				.pxToVwH5(width, 30);
				.pxToVwH5(height, 30);
			}

			img.open {
				transform: rotate(90deg);
			}
		}

		.item-children {
			background: @theme-black;
			flex-wrap: wrap;
			.pxToVwH5(padding-left, 18);

			.c-item {
				.pxToVwH5(font-size, 28);
				color: @gray-P1;
				font-weight: 500;
				text-align: left;
				.a-link{
					.pxToVwH5Padding(20, 32);
					display: block;
					color: @gray-P1;
					font-weight: 500;
				}
			}
		}
	}
}
.hide {
	display: none;
}
</style>
